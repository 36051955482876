import type { Column } from '@tanstack/vue-table'
import type { ReportRow } from '@reports'

export const useOverflow = (
  element: Ref<HTMLElement | null>,
  column: Column<ReportRow>,
) => {
  const hasOverflow = ref(false)
  const isResizing = computed(() => column.getIsResizing())

  const checkOverflow = () => {
    if (element.value) {
      hasOverflow.value = element.value.scrollWidth > element.value.clientWidth
    }
  }

  onMounted(() => {
    checkOverflow()
  })

  watchDebounced(
    isResizing,
    (value) => {
      if (!value) checkOverflow()
    },
    { debounce: 100 },
  )

  return { isResizing, hasOverflow }
}
