<script setup lang="ts">
import { useOverflow } from '../composables'
import type { Column } from '@tanstack/vue-table'
import type { ReportRow } from '@reports'

const props = defineProps<{ column: Column<ReportRow> }>()

// OVERFLOW
const cellRef = ref<HTMLElement | null>(null)
const { hasOverflow } = useOverflow(cellRef, props.column)
</script>

<template>
  <span ref="cellRef" class="block w-full truncate">
    <slot />
  </span>

  <!-- Tooltip if text overflows -->
  <VTooltip v-if="hasOverflow">
    <Button color="link" class="px-0 py-0 !text-gray-200">
      <Icon name="eye" class="h-3 w-4" />
    </Button>

    <template #popper>
      <span
        class="block w-full max-w-[75vw] truncate whitespace-break-spaces text-xs"
      >
        <slot />
      </span>
    </template>
  </VTooltip>
</template>
